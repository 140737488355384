<template>

  <div class="applyHouseCollection">
    <rxNavBar :title="navTitle"></rxNavBar>
    <!--        <van-nav-bar class="nav-bar" title="配货aa审核" left-arrow @clickLeft="leftReturn" ></van-nav-bar>-->
    <!--<div class="applyTime">申请时间：&nbsp;{{dataList.approvalTime}}</div>-->
    <div class="headerBlock">
      <div
          :class="{'wait-approve':dataList.approvalStatus ==0 ,'statePassed' :  dataList.approvalStatus ==1 , 'stateRejected' :dataList.approvalStatus ==2  }">
        <span>{{ dataList.approvalStatusStr }}</span>
      </div>
      <div class="houseAddress">{{ dataList.roomDetailedAddress }}</div>
    </div>

    <div class="displayBlock">
      <table class="houseInfo">
        <tr>
          <td>
            <div class="boldFont">申请人：<span
                class="normalFont">{{ dataList.applicationGlobaluserName || dataList.approvalStaffName }}</span></div>
          </td>
          <td>
            <div class="boldFont">紧急程度：<span class="normalFont"><font
                color="#F07A66">{{ dataList.distributionStatus }}</font></span></div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="boldFont">电话：<span class="normalFont">{{ dataList.applicationGlobaluserMoblile }}</span></div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="boldFont">申请时间：<span class="normalFont">{{ dataList.addTime }}</span></div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="boldFont">预计配送时间：<span class="normalFont">{{ dataList.appointmentTime }}</span></div>
          </td>
        </tr>
        <tr v-if="dataList.afterRentDesprition">
          <td colspan="2">
            <div class="boldFont">备注：<span class="normalFont">{{ dataList.afterRentDesprition }}</span></div>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="record && record.length!=0" class="displayBlock">
      <table class="houseInfo">
        <tr>
          <td>
            <div class="boldFont">变更人：<span class="normalFont">{{ record[0].staffName }}</span></div>
          </td>
          <td>
            <div class="boldFont">变更原因：<span class="normalFont">{{ record[0].reasonChange }}</span></div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="boldFont">变更时间：<span class="normalFont">{{ record[0].addTime }}</span></div>
          </td>
        </tr>
        <tr v-if="record[0].remarks">
          <td colspan="2">
            <div class="boldFont">备注：<span class="normalFont">{{ record[0].remarks }}</span></div>
          </td>
        </tr>
      </table>
    </div>

    <div class="thing"><p><span>物品清单</span></p></div>

    <div class="panel" style="margin-top: 20px;width: auto;margin-left: 5px;margin-right: 5px">
      <div v-for="(item) in itemList" :key="item.id" class="review" style="margin-top: -13px">
        <div class="panelInfo">
          <table>
            <!-- <tr>
                <td><p style="margin-top: 11px;margin-bottom: 5px">位置：<span>{{item.roomName}}</span></p></td>
            </tr> -->
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 5px">
                位置区域：<span>{{ item.regionalLocationName ? item.regionalLocationName : item.roomName }}</span></p></td>
            </tr>
            <tr>
              <td><p style="margin-top:-5px;margin-bottom: 5px;">类型：<span>{{ item.goodsAllocationClassName }}</span></p>
              </td>
            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 5px">物品：<span>{{ item.itemName }}</span></p></td>
            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 5px;">品牌：<span>{{ item.brandName }}</span></p></td>
            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 11px">是否库出：<span>{{
                  item.oldIsLibrary === '1' ? '是' : '否'
                }}</span><span v-if="item.oldIsLibrary!=item.newIsLibrary"
                               style="color:#F17256">{{ item.newIsLibrary === '1' ? '(是)' : '(否)' }}</span>
              </p></td>

            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 11px">
                {{ item.oldIsLibrary==='1'?'仓库':'供应商' }}
                <span style="font-weight:bolder;color:#F17256" v-if="item.oldIsLibrary!=item.newIsLibrary">{{ item.newIsLibrary==='1'?'(仓库)':'(供应商)' }}</span>：
                <span>{{ item.oldIsLibrary==='1'?item.oldLibraryName:item.oldSupplier }}</span>
                <span v-if="item.oldLibraryName!=item.newLibraryName || item.oldSupplier!=item.newSupplier"
                    style="color:#F17256">{{ item.newIsLibrary==='1' ? '(' + item.newLibraryName + ')' : '(' + item.newSupplier + ')' }}</span>
              </p></td>

            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 11px;">
                金额：<span>{{
                  item.oldGoodSallocationAmount === undefined ? '暂无' : item.oldGoodSallocationAmount + '元'
                }}</span><span
                  v-if="item.oldGoodSallocationAmount!=item.newGoodSallocationAmount"
                  style="color:#F17256">{{
                  item.newGoodSallocationAmount === undefined ? '' : '(' + item.newGoodSallocationAmount + '元)'
                }}</span>
              </p></td>
            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 11px;">安装人：<span>{{ item.oldInstallation }}</span><span
                  v-if="item.oldInstallation!=item.newInstallation_id"
                  style="color:#F17256">{{ item.newInstallation_id ? '(' + item.newInstallation_id + ')' : '' }}</span>
              </p></td>
            </tr>
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 11px;">
                安装费：<span>{{
                  item.oldInstallationAmount === undefined ? '暂无' : item.oldInstallationAmount + '元'
                }}</span><span
                  v-if="item.oldInstallationAmount!=item.newInstallationAmount"
                  style="color:#F17256">{{
                  item.newInstallationAmount != undefined ? '(' + item.newInstallationAmount + '元)' : ''
                }}</span>
              </p></td>
            </tr>
            <!--                        <tr>-->
            <!--                            <td><p style="margin-top: -5px;margin-bottom: 11px;">承担方：<span>{{item.costBear }}</span></p></td>-->
            <!--                        </tr>                                                                        -->
          </table>
        </div>
      </div>
    </div>

    <div class="thing"><p><span>费用</span></p></div>

    <div class="panel" style="margin-top: 20px;width: auto;margin-left: 5px;margin-right: 5px">
      <div class="review" style="margin-top: -13px">
        <div class="panelInfo">
          <table>
            <tr>
              <td><p style="margin-top: 11px;margin-bottom: 5px">
                运送人：<span>{{ fee.oldtransportation === undefined ? '' : fee.oldtransportation }}</span><span
                  v-if="fee.newtransportation!=fee.oldtransportation"
                  style="color:#F17256">{{
                  fee.newtransportation === undefined ? '' : '(' + fee.newtransportation + ')'
                }}</span>
              </p></td>

            </tr>
            <tr>
              <td><p style="margin-top:-5px;margin-bottom: 5px;">
                运送费：<span>{{
                  fee.oldtransportationAmount === undefined ? '' : fee.oldtransportationAmount + '元'
                }}</span><span
                  v-if="fee.newtransportationAmount!=fee.oldtransportationAmount"
                  style="color:#F17256">{{
                  fee.newtransportationAmount === undefined ? '' : '(' + fee.newtransportationAmount + '元)'
                }}</span>
              </p></td>
            </tr>
            <!-- <tr>
                <td><p style="margin-top: -5px;margin-bottom: 5px">安装费：<span>{{oldInstallCost? oldInstallCost + '元' : ''}}</span><span style="color:#F17256">{{installCost?'('+installCost+'元)':''}}</span></p></td>
            </tr> -->
            <tr>
              <td><p style="margin-top: -5px;margin-bottom: 5px;">
                总金额：<span>{{ oldTotalMoney === undefined ? '' : oldTotalMoney + '元' }}</span><span
                  v-if="totalMoney!=oldTotalMoney"
                  style="color:#F17256">{{ totalMoney === undefined ? '' : '(' + totalMoney + '元)' }}</span>
              </p></td>
            </tr>

          </table>
        </div>
      </div>
    </div>

    <div v-if="this.auditOrCheck == 1">
      <div class="normalBlock">
        <span :class="{'orangeColor':(isPass||isReject)}" class="maker">• </span>
        <span class="blockTitle">审核结果</span>
        <div class="rightText">
          <span :class="{'choosedStyle':isPass}" class="passText" @click="choosePass">通过</span>
          <span class="content-divide"> | </span>
          <span :class="{'choosedStyle':isReject}" class="rejectText" @click="chooseReject">驳回</span>
        </div>
      </div>

      <div v-show="isReject" id="addressPanel" class="part-inputpart-row" style="margin-bottom: -5px"
           @click="isReasonShow = !isReasonShow">
        <span :class="{'orangeColor':rejectReason.dictionaryTitle}" class="maker">• </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="content-divide">|</span>
        <span :class="rejectReason.dictionaryTitle ? 'part-inputpart-row-normaltext':'' "
              class="part-inputpart-row-graytext">{{ rejectReason.dictionaryTitle }}</span>
        <img :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
             class="part-inputpart-row-right"
             src="../../assets/images/triangle.png">
      </div>
      <div v-show="isReject" class="rejected" style="margin-top: 5px"></div>
      <div class="displayBlock">
        <div><span class="suggestTitle">审批意见</span></div>
        <div><textarea v-model="opinion" class="textArea"></textarea></div>
      </div>
      <div v-show="!isPass">
        <van-button
            :class="{'saveButton_Enable': (isPass && (dataList.guidancePrice>0) ) || (isReject && rejectReason.dictionaryTitle)}"
            :disabled=" !((isPass && (dataList.guidancePrice>0) ) || (isReject && rejectReason.dictionaryTitle)) || enable"
            class="saveButton_Disable"
            @click="save">保存
        </van-button>
      </div>
      <div v-show="isPass">
        <van-button
            :class="{'saveButton_Enable': (isPass && (dataList.guidancePrice>0) ) || !(isReject && rejectReason.dictionaryTitle)}"
            :disabled=" !((isPass && (dataList.guidancePrice>0) ) || !(isReject && rejectReason.dictionaryTitle)) || enable"
            class="saveButton_Disable"
            @click="save">保存
        </van-button>
      </div>
    </div>
    <van-popup v-model="isReasonShow" position="bottom" round>
      <van-picker :columns="reasonArr" :default-index="defaultIndex" show-toolbar value-key="dictionaryTitle"
                  @cancel="isReasonShow = false" @confirm="onConfirm"/>
    </van-popup>

  </div>

</template>

<script>


import {
  NavBar,
  ImagePreview, Button, Image as VanImage, Picker, Popup
} from 'vant';
import BScroll from "better-scroll";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import {
  decorationAgree,
  decorationDetailInit,
  decorationRefuse, distributionAgree, distributionRefuse,
  queryAfterRentDistribution, queryAfterrentUpAmountDetails, approvalAfterrentUoAmountGongDan
} from "../../getData/getData";
import {responseUtil, getStaffId} from "../../libs/rongxunUtil";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]: BScroll,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar
  },
  data() {
    return {
      auditOrCheck: '',
      //拒绝id
      refuseId: -1,
      //通过or驳回
      istype: 2,
      //标题头
      navTitle: '配货变更',
      //审批意见
      opinion: '',
      //驳回
      rejected: {
        time: '2',
        reason: '不知道不知道知不道',
      },
      //物品清单参数
      itemList: [],
      dataList: {},
      rejectReason: '请选择',
      reasonArr: [],
      isPass: false,
      isReject: false,
      isReasonSelect: false,
      isReasonShow: false,
      defaultIndex: '',
      fee: {},
      oldInstallCost: 0,
      installCost: 0,
      oldTotalMoney: 0,
      totalMoney: 0,
      record: [],
      enable: false,
    }
  },
  mounted() {
    this.auditOrCheck = this.$route.query.auditOrCheck

  },
  methods: {
    getBefore() {
      let that = this
      let initData = {}
      initData.id = this.$route.query.id
      queryAfterrentUpAmountDetails(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data.record)
          that.record = response.data.data.record
          that.afterrentupamountoritemList = response.data.data.data[0] ? response.data.data.data[0].afterrentupamountoritemList : []
          that.fee = response.data.data.data[0] ? response.data.data.data[0] : {}
          console.log(that.afterrentupamountoritemList)
          for (let i in that.itemList) {
            that.itemList[i].newGoodSallocationAmount = that.afterrentupamountoritemList[i].newGoodSallocationAmount
            that.itemList[i].newInstallationAmount = that.afterrentupamountoritemList[i].newInstallationAmount
            that.itemList[i].newSupplier = that.afterrentupamountoritemList[i].newSupplier
            that.itemList[i].newInstallation_id = that.afterrentupamountoritemList[i].newInstallation_id
            that.itemList[i].oldInstallationAmount = that.afterrentupamountoritemList[i].oldInstallationAmount
            that.itemList[i].oldInstallation = that.afterrentupamountoritemList[i].oldInstallation
            that.itemList[i].oldSupplier = that.afterrentupamountoritemList[i].oldSupplier
            that.itemList[i].oldGoodSallocationAmount = that.afterrentupamountoritemList[i].oldGoodSallocationAmount
            that.itemList[i].newIsLibrary = that.afterrentupamountoritemList[i].newIsLibrary
            that.itemList[i].oldIsLibrary = that.afterrentupamountoritemList[i].oldIsLibrary
            that.itemList[i].newLibraryName = that.afterrentupamountoritemList[i].newLibraryName
            that.itemList[i].oldLibraryName = that.afterrentupamountoritemList[i].oldLibraryName

            that.oldInstallCost += that.itemList[i].oldInstallationAmount
            that.installCost += that.itemList[i].newInstallationAmount
            that.oldTotalMoney += that.itemList[i].oldGoodSallocationAmount
            that.totalMoney += that.itemList[i].newGoodSallocationAmount
          }
          console.log(that.itemList)
          that.$forceUpdate()
        })
      })

    },
    onConfirm(value, index) {
      this.rejectReason = value
      this.defaultIndex = index
      this.isReasonShow = false
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },

    choosePass() {
      this.isPass = true;
      this.isReject = false;
      this.istype = 1
    },
    chooseReject() {
      this.isReject = true;
      this.isPass = false;
      this.istype = 0
    },
    selectAddressOption(e) {
      this.rejectReason = e.row.dictionaryTitle
      this.isReasonShow = false
      this.isReasonSelect = true
      this.refuseId = e.row.id
    },
    clickAddressShow() {
      this.isReasonShow = !this.isReasonShow;
      var panel = document.getElementById('addressPanel')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isReasonShow = false
        }
      })
    },
    //配货审核详情初始化
    initData() {
      let that = this;
      let initData = {};
      initData.id = this.$route.query.id;
      queryAfterRentDistribution(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.dataList = response.data.data.item;
          that.itemList = response.data.data.list;
          that.reasonArr = response.data.data.refuseReasonList;
          that.getBefore()
        })
      })
    },

    save() {
      var that = this;
      var func;
      var data = {}
      //通过的接口
      if (this.istype == "1") {
        func = distributionAgree;
      }
      //拒绝的接口
      if (this.istype == "0") {
        func = distributionRefuse;
        //拒绝理由id
        data.refuseReason_id = that.rejectReason.id;
      }
      data.id = this.$route.query.id
      data.user_id = getStaffId()
      data.remarks = that.opinion
      data.approvalStatus = this.istype
      //data.type = "3"
      console.log(data)
      console.log(this.$route.query)
      that.enable = true
      approvalAfterrentUoAmountGongDan(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertMsg(that, response.data.msg)
          that.enable = false
          that.leftReturn();
        })
      })
    },
  },
  created() {
    this.initData()
  }
}
</script>

<style lang="less">

.applyHouseCollection {
  overflow: hidden;
}

// 图片墙
.swiper_show {
  width: 9rem;
  position: relative;
  margin: 8px 13px;
  height: 80px;

  .content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;

    .imgWrap {
      margin-right: 10px;

    }
  }

  .num {
    position: absolute;
    left: 8px;
    top: 17px;
    font-size: 10px;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    color: rgb(255, 255, 255);
    padding: 4px 6px 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bolder;
    line-height: 10px;
  }

}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}

.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -65px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 14px;
  width: 13%;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 14px;
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  margin: 19px auto;
}

.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin: 0 5px;
}

.selectReason {
  width: 245px;
  height: 1rem;
  line-height: 28px;
  margin-left: 20px;
  font-size: 14px;
  border-style: none;
}

.suggestTitle {
  margin-left: 22px;
  margin-top: 12px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.textArea {
  resize: none;
  padding: 10px;
  margin: 43px auto 15px;
  height: 120px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(182, 177, 177, 0.11);
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}

.inputPrice {
  color: #ff3c00;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  //font-weight: bold;
  border: none;
}

.divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.passText {
  flex: 1;
  padding-right: 7px;
}

.rejectText {
  padding-left: 7px;
  padding-right: 33px;

}

.rightText {
  margin-left: auto;
  font-size: 14px;
  color: #999999;
}

.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}

.wait-approve, .statePassed, .stateRejected {
  width: 46px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
}

/*列表状态样式 1是未审批*/
.wait-approve {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 2是已通过*/
.statePassed {
  background: -webkit-linear-gradient(left, #66CB94, #28D3B0);
}

/*列表状态样式 3是已驳回*/
.stateRejected {
  background: -webkit-linear-gradient(left, #E02020, #FF431B);
}

.orangeColor {
  color: #FE5E3D;
}

.choosedStyle {
  color: #FC5D40;
  font-weight: bold;
}

.normalFont {
  font-size: 13px;
  font-weight: normal;
  color: #767676;
}

.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}

.boldFont {
  font-size: 14px;
  font-weight: bold;
  width: 92%;
}

.descTitle {
  height: 25px;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.descText {
  height: auto;
  padding-left: 20px;
  padding-bottom: 14px;
  padding-right: 20px;
  line-height: 23px;
}

.houseInfo {
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  padding-left: 20px;

}

.applyTime {
  font-size: 12px;
  text-align: right;
  color: #7d7b7b;
  padding-right: 15px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.houseAddress {
  font-size: 16px;
  font-weight: bold;
  height: auto;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 10px;
}

.keyAddress {
  font-size: 14px;
  height: auto;
  padding-top: 7px;
  padding-bottom: 17px;
  padding-left: 15px;
  color: #7d7b7b;

}

.headerBlock {
  background-color: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width: 9rem;
  height: 70px;
  margin: 25px auto 19px;
  margin-bottom: 2px;
}

.nav-bar {
  background-color: #f8f8f8;
  border: none;
  font-weight: bold;
}

.saveButton_Disable {
  background-color: rgba(184, 184, 184, 0.2);
  width: 9rem;
  height: 1.22rem;
  border-radius: 8px;
  margin: 30px 19px 50px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}

.middle {
  overflow: hidden;
  height: auto;
}

.displayBlock {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 0 0 8px 8px;
  width: 90%;
  height: auto;
  margin: 1px auto;
}

.thing {
  color: #F17256;
  font-size: 13px;
  font-weight: bold;
  margin-top: 30px;
  margin-left: 19px;
}

.rejected {
  color: #B8B8B8;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 33px;
  margin-bottom: 20px;
}

.listtop {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width: 9rem;
  height: auto;
  /*margin-top: 20px ;*/
  margin: -5px auto 0px;
}

.list {
  overflow: hidden;
  position: relative;
  background-color: white;
  width: 9rem;
  height: auto;
  /*margin-top: 2px;*/
  margin: 3px auto 0px;
}

.listBottoom {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 9rem;
  height: auto;
  margin: 3px auto;
}

.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 19px auto;
}

/*物品清单参数*/
.review { //参数
  margin: 1px 15px 15px;
  height: auto;
  background-color: white;

  overflow: hidden;
}

.panel .review:first-of-type {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.panel .review:last-of-type {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.panelInfo { //参数

  padding: 5px 14px;
  font-size: 14px;

  p {
    font-weight: bolder;
    margin: 15px 0px;

    .fitment {
      color: #f00;
    }

    span {
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
